import WebGLPointsLayer from "ol/layer/WebGLPoints";
import WebGLPointsLayerRenderer from "ol/renderer/webgl/PointsLayer";

class MyWebGLPointsLayer extends WebGLPointsLayer {
  createRenderer() {
    return new WebGLPointsLayerRenderer(this, {
      vertexShader: this.parseResult_.builder.getSymbolVertexShader(),
      fragmentShader: this.parseResult_.builder.getSymbolFragmentShader(),
      hitVertexShader:
        !this.hitDetectionDisabled_ &&
        this.parseResult_.builder.getSymbolVertexShader(true),
      // hitFragmentShader: 'precision mediump float; varying vec4 v_hitColor; void main(void) { gl_FragColor =' + ' v_hitColor; }',
      hitFragmentShader: this.getSymbolFragmentShader(true),
      uniforms: this.parseResult_.uniforms,
      attributes: this.parseResult_.attributes,
    });
  }
  getSymbolFragmentShader(forHitDetection) {
    const hitDetectionBypass = forHitDetection
      // ? '  if (gl_FragColor.a < 0.1) { discard; } gl_FragColor = v_hitColor;'
      ? '  gl_FragColor = v_hitColor;'
      : '';

    let varyings = this.parseResult_.builder.varyings;

    if (forHitDetection) {
      varyings = varyings.concat({
        name: 'v_hitColor',
        type: 'vec4',
        expression: 'a_hitColor',
      });
    }

    return `precision mediump float;
uniform float u_time;
uniform float u_zoom;
uniform float u_resolution;
${this.parseResult_.builder.uniforms
      .map(function (uniform) {
        return 'uniform ' + uniform + ';';
      })
      .join('\n')}
varying vec2 v_texCoord;
varying vec2 v_quadCoord;
${varyings
      .map(function (varying) {
        return 'varying ' + varying.type + ' ' + varying.name + ';';
      })
      .join('\n')}
void main(void) {
  if (${this.parseResult_.builder.discardExpression}) { discard; }
  gl_FragColor = ${this.parseResult_.builder.colorExpression};
  gl_FragColor.rgb *= gl_FragColor.a;
${hitDetectionBypass}
}`;
  }
}

export default MyWebGLPointsLayer;