import React from 'react';
// import config from '../config/index.js';

export default React.createContext({
  place: [],
  setPlace: () => {},
  face: null,
  setFace: () => {},
  faces: {},
  setFaces: () => {},
  cart: [],
  setCart: () => {}
});
