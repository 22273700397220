import * as React from 'react';
import {useContext, useEffect, useState, useMemo} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';
import {useNavigate, useParams} from "react-router-dom";
import i18n from "../utils/i18n";
import {FormHelperText, Input, InputLabel, Snackbar, TextField} from "@mui/material";
import FaceContext from "../Contexts/FaceContext";
import PropTypes from "prop-types";
import {useFormik} from 'formik';
import * as yup from "yup";
import {IMaskInput} from "react-imask";
import FormControl from "@mui/material/FormControl";
import DialogActions from "@mui/material/DialogActions";
import FilterContext from "../Contexts/FilterContext";
import {Alert} from '@mui/material';
import DayJS from "dayjs";
import {Feature} from "ol";
import {Point} from "ol/geom";
import {fromLonLat} from "ol/proj";
import {calcTotalByPeriod} from "../utils";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// const validationRe = {
//   name: /\w+/,
//   email: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
//   phone: /\+?\d{1,3}\(?\d{2,3}\)?\d{3}\-?\d{2}\-?\d{2}/,
//   message: /\w+/
// }

const phoneRegExp = /^\+?\d{1,3}\(?\d{2,3}\)?\d{3}\-?\d{2}\-?\d{2}$/;
// /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email'),
  name: yup
    .string('Enter your name')
    .required('Name is required'),
  phone: yup
    .string('Enter your phone')
    .matches(phoneRegExp, 'Phone number is not valid'),
  message: yup
    .string('Enter your message')
    .required('This field is required'),
});

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const {onChange, ...other} = props;
  return (
    <IMaskInput
      {...other}
      mask="+{\3\8\0}(00)000-00-00"
      inputRef={ref}
      onAccept={(value) => onChange({target: {name: props.name, value}})}
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function FaceRequest({sx}) {
  // const [activeStep, setActiveStep] = useState(0);
  // const [values, setValues] = useState({name: '', email: '', phone: '', message: i18n.t('sendMessageInitialNote')});
// const [isFormValid, setIsFormValid] = useState(false);
//   const [error, setError] = useState({name: false, email: false, phone: false, message: false});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');
  const [openSuccess, setOpenSuccess] = useState(false);
  const {faceId} = useParams();
  const navigate = useNavigate();
  const faceContext = useContext(FaceContext);
  const filterContext = useContext(FilterContext);

  const now = new DayJS();
  const isWorkingHours = now.day() >= 1 && now.day() <= 5 && now.hour() >= 8 && now.hour() <= 19;

  // useEffect(() => {
  //   // console.log(error);
  //   const valid = Object.values(error).reduce((r, e) => r && !e, true) && !!values.name && !!values.email && !!values.phone && !!values.message;
  //   // console.log(valid);
  //   setIsFormValid(valid);
  // }, [error, values]);

  const handleClose = () => {
    navigate('/');
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleOkClick = () => {
    setOpenSuccess(false);
    navigate('/');
  }

  const handleSuccessClose = () => {
    setOpenSuccess(false);
    navigate('/');
  }

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleApply = () => {
    formik.submitForm();
  };

  const freeRe = useMemo(()=>{
    if (!filterContext || !filterContext.period) {
      return;
    }
    const startDate = filterContext.period.start,
      endDate = filterContext.period.end,
      wholePeriod = false,
      minFreeDays = 15,
      allowTempRes = true,
      now = new Date(),
      // minDate = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate() + 1));
      minDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);

    const daysToBeg = Math.round((startDate - minDate) / (3600 * 24 * 1000)),
      daysPeriod = Math.round((endDate - startDate) / (3600 * 24 * 1000));

    if (daysPeriod < 0) {
      return;
    }
    return new RegExp('^.{' + daysToBeg + (wholePeriod ? '' : ',' + (daysToBeg + daysPeriod - minFreeDays)) + '}(' + (allowTempRes ? '[tf]' : 'f') + '{' + daysPeriod +'})');
  }, [filterContext]);

  function updateFace (face) {
    const occ = face.occByDays;
    const feature = face.feature;
    if (!occ) {
      face.status = 'unknown';
    } else {
      // var tmpOcc = occ.slice(daysToBeg);
      if (!freeRe || freeRe.test(occ)) {
        // faces[rec.getId()] = freeRe.exec(occ)[1].length;
        face.status = 'free';
      } else {
        face.status = 'sold';
      }
    }
    // console.log(res.status);
    const cartItem = faceContext.cart.find((item)=>{
      return (
        item.id === face.id &&
        +item.startDate.toDate() <= +filterContext.period.start.toDate() &&
        +item.endDate.toDate() >= +filterContext.period.end.toDate()
      )
    });
    face.inCart = !!cartItem ? 1 : 0;
    face.pricePerMonth = Number(face.price) + Number(face.printCost) + Number(face.deliveryCost);
    face.total = calcTotalByPeriod({
      price: face.pricePerMonth,
      startDate: filterContext.period.start,
      endDate: filterContext.period.end,
      printCost: face.printCost,
      deliveryCost: face.deliveryCost
    });
    if (feature) {
      feature.setProperties({
        pricePerMonth: face.pricePerMonth,
        status: face.status,
        inCart: face.inCart,
        total: face.total
      });
    }
  }

  useEffect(() => {
    if (faceId && !faceContext.faces[faceId]) {
      const url = `/api/v1/geoquery/face/${faceId}`;
      fetch(url)
        .then(res => res.json())
        .then(
          (result) => {
            if (result.error || !result.features || !result.features.length) {
              navigate('/');
            } else {
              const newFaces = result.features.reduce((res, nf)=>{
                const f = new Feature();
                f.setGeometry(new Point(fromLonLat([nf.lon, nf.lat])))
                f.setId(nf.id);
                f.setProperties(nf.properties);
                res[nf.id] = {...nf.properties, feature: f};
                updateFace(res[nf.id]);
                return res;
              }, {});
              faceContext.setFaces({...faceContext.faces, ...newFaces});
              if (!newFaces[faceId]) {
                navigate('/');
              }
            }
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          () => { //error
            navigate('/');
          }
        )
    }
  }, []);

  // const handleChange = (event) => {
  //   setValues({
  //     ...values,
  //     [event.target.name]: event.target.value,
  //   });
  //   setError({...error, [event.target.name]: !validationRe[event.target.name].test(event.target.value)});
  // };

  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
      phone: '',
      message: i18n.t('sendMessageInitialNote')
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const f = faceContext.faces[faceId];
      const face = {
        id: f.id,
        city: i18n.t(`refCity.${f.id_city}`, {lng: 'uk-UA'}),
        catab: i18n.t(`refAB.${f.id_catab}`, {lng: 'uk-UA'}),
        address: f.address_ukr,
        deliveryCost: f.deliveryCost,
        doorsNo: f.doors_no,
        network: i18n.t(`refNetwork.${f.id_network}`, {lng: 'uk-UA'}),
        price: f.price,
        printCost: f.printCost,
        size: i18n.t(`refSize.${f.id_size}`, {lng: 'uk-UA'}),
        supplier: i18n.t(`refSupplier.${f.id_supplier}`, {lng: 'uk-UA'}),
        supplierNo: f.supplier_sn
      };
      const data = {...values, face};
      fetch('/api/v1/easyboard/message', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }).then((response) => {
        if (!response || !response.ok) {
          // this.getView().close();
          setAlertSeverity('error');
          setAlertMessage(i18n.t('checkoutSaveErrorMessage'));
          setOpenSnackbar(true);
        } else {
          // faceContext.setCart([]);
          setOpenSuccess(true);
          // EasyBoard.util.Shared.facebookQuery('track', 'Purchase', {
          //   content_ids: ids,
          //   content_type: 'product',
          //   value: p.total
          // });
        }
      });
    },
  });

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        onClose={handleClose}
        open
        TransitionComponent={Transition}
        sx={{...sx}}
      >
        <AppBar sx={{position: 'relative'}} color={"transparent"}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon/>
            </IconButton>
            <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
              {i18n.t('sendMessageTitle')}
            </Typography>
          </Toolbar>
        </AppBar>
        <div style={{padding: 10, flex: 1}}>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              name="name"
              label={i18n.t('nameText')}
              fullWidth
              variant="standard"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              required
            />
            <TextField
              margin="dense"
              id="email"
              label={i18n.t('emailText')}
              name="email"
              type="email"
              fullWidth
              variant="standard"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <FormControl
              variant="standard"
              fullWidth
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              onChange={formik.handleChange}
              margin="dense"
            >
              <InputLabel htmlFor="phone" variant="standard" filled>{i18n.t('phoneText')}</InputLabel>
              <Input
                name="phone"
                id="phone"
                type="tel"
                inputComponent={TextMaskCustom}
                fullWidth
                value={formik.values.phone}
              />
              <FormHelperText>{formik.touched.phone && formik.errors.phone}</FormHelperText>
            </FormControl>
            <TextField
              id="note"
              label={i18n.t("sendMessageNoteLabel")}
              name="message"
              type="text"
              variant="standard"
              margin="dense"
              multiline
              required
              fullWidth
              value={formik.values.message}
              onChange={formik.handleChange}
              error={formik.touched.message && Boolean(formik.errors.message)}
              helperText={formik.touched.message && formik.errors.message}
              // sx={{flex: 1}}
              rows={5}
            />
          </form>
        </div>
        <DialogActions>
          <Button variant={"contained"} onClick={handleApply} autoFocus>
            {i18n.t('sendMessageButtonText')}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} variant="filled" severity={alertSeverity} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <Dialog open={openSuccess} fullScreen={fullScreen} onClose={handleSuccessClose}>
        <div style={{margin: 'auto'}}>
          <img alt="sent" src="/resources/sent.png" height={280}/>
          <div style={{textAlign: "center"}}>
            <p style={{fontSize: 15, lineHeight: '24px', fontWeight: 500, color: "#111"}}>
              {i18n.t('thankyouText')}
            </p>
            <p style={{fontSize: 13, lineHeight: "150%"}}>
              {isWorkingHours ? i18n.t('managerShortlyText') : i18n.t('managerSoonText')}
            </p>
          </div>
        </div>
        <DialogActions>
          <Button variant={"contained"} onClick={handleOkClick} autoFocus fullWidth={fullScreen}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}