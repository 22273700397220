import i18n from "../utils/i18n";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from 'react';
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import {Dialog} from "@mui/material";
import {useNavigate} from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function About({sx}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const handleClose = () => {
    navigate('/');
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open
      onClose={handleClose}
      TransitionComponent={Transition}
      sx={{...sx}}
    >
      <AppBar sx={{position: 'relative'}} color={"transparent"}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon/>
          </IconButton>
          <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
            {i18n.t('aboutTitle')}
          </Typography>
          {/*<Button autoFocus color="inherit" onClick={handleClose}>*/}
          {/*    save*/}
          {/*</Button>*/}
        </Toolbar>
      </AppBar>
    <div
      style={{
        backgroundColor: "white",
        padding: 20
      }}
      dangerouslySetInnerHTML={{
        __html: i18n.t('aboutText', {interpolation: {escapeValue: false}})
    }}
    />
    </Dialog>
  )
}