import olSourceVector from "ol/source/Vector";
import olFormatGeoJSON from "ol/format/GeoJSON";
import * as olExtent from "ol/extent";
import * as olSphere from "ol/sphere";
import * as olProj from "ol/proj";
import {fromLonLat} from "ol/proj";
import * as olStrategy from "ol/loadingstrategy";
import MyWebGLPointsLayer from "./MyWebGLLayer";
import olLayerVector from "ol/layer/Vector";
import olLayerWebGLPoints from "ol/layer/WebGLPoints";
import olStyleStyle from "ol/style/Style";
import olStyleIcon from "ol/style/Icon";
import * as ol from "ol";
import {Collection} from "ol";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import {Attribution, Control} from "ol/control";
import olText from "ol/style/Text";
// import olFill from "ol/style/Fill";
// import olStroke from "ol/style/Stroke";
import {Point} from "ol/geom";
import {CLASS_HIDDEN} from "ol/css";
const apiKey = 'vl5HKeyYE3MxBgOW-Nf9WzI9_jiJ1gGqnjIRjDZzoPQ';
// const apiKey = 't4kncjNICU6uvyfsLDOlbZ6uwuyDB1N_xJ8XMnrmUJE';
// const url = 'https://{1-4}.base.maps.ls.hereapi.com' +
  // '/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/256/png' +
  // '?apiKey=' + apiKey + '&lg=ukr';
// V3
const url = 'https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?'+
  'style=explore.day&apiKey=' + apiKey + '&lg=ukr'

class GotoLocationControl extends Control {
  constructor(opt_options) {
    const options = opt_options || {};

    const button = document.createElement('button');
    button.innerHTML = '☉'; //◉

    const element = document.createElement('div');
    element.className = 'gotolocation ol-unselectable ol-control ' + CLASS_HIDDEN;
    element.appendChild(button);
    // element.classList.add(CLASS_HIDDEN);

    super({
      element: element,
      target: options.target,
    });

    button.addEventListener('click', this.handleGotoLocation.bind(this), false);
  }

  render (mapEvent) {
    const frameState = mapEvent.frameState;
    if (!frameState) {
      return;
    }

    const geolocation = this.getMap().currentGeolocation;
      // frameState.viewState.rotation;
    if (geolocation) {
      const extent = this.getMap().getView().calculateExtent();
      const inExtent = olExtent.containsCoordinate(extent, geolocation);
      const contains = this.element.classList.contains(CLASS_HIDDEN);
      if (inExtent && !contains) {
        this.element.classList.add(CLASS_HIDDEN);
      } else if (!inExtent && contains) {
        this.element.classList.remove(CLASS_HIDDEN);
      }
    }
    //   var transform = 'rotate(' + rotation + 'rad)';
    //   if (this.autoHide_) {
    //     var contains = this.element.classList.contains(CLASS_HIDDEN);
    //     if (!contains && rotation === 0) {
    //       this.element.classList.add(CLASS_HIDDEN);
    //     }
    //     else if (contains && rotation !== 0) {
    //       this.element.classList.remove(CLASS_HIDDEN);
    //     }
    //   }
    //   this.label_.style.transform = transform;
    // }
    // this.rotation_ = rotation;
  };

  handleGotoLocation() {
    if (this.getMap().currentGeolocation) {
      this.getMap().getView().animate({center: this.getMap().currentGeolocation});
    }
  }
}

export default function MapObject() {

  const searchPlaceStyle = new olStyleStyle({
    image: new olStyleIcon({
      src: 'resources/place.svg',
      rotateWithView: false
    })
  });

  const searchPlaceLayer = new olLayerVector({
    source: new olSourceVector({
      features: []
    }),
    style: searchPlaceStyle
    //   style: me.curPositionStyle,
    // disableHitDetection: true
  });

  const gotoLocation = new GotoLocationControl();

  const options = {
    view: new ol.View({
      center: fromLonLat([30.534148, 50.423644]),
      zoom: 15,
      maxZoom: 18,
      minZoom: 5
    }),
    layers: [new TileLayer({
      preload: Infinity,
      source: new XYZ({
        url: url,
        attributions: '&copy; ' + new Date().getFullYear() + ' ' +
          '<a href="https://www.bigmedia.ua">Bigmedia</a><br>' +
          'Map Tiles &copy; ' + new Date().getFullYear() + ' ' +
          '<a href="https://developer.here.com">HERE</a>'
      })
    })],
    controls: new Collection().extend([new Attribution(), gotoLocation]),
    overlays: []
  };

  const mapObject = new ol.Map(options);

  const faceStyle = {
    "variables": {
      maxPrice: 1000000
    },
    "filter":
      ["case",
        ['between',
          ["get", "pricePerMonth"],
          1,
          ["var", "maxPrice"]
        ],
        // ["<=", ["get", "price"], ["var", "maxPrice"]],
        ["==", ["get", "status"], "free"],
        false
      ],
    // "filter" : ["<=", ["get", "price"], ["var", "maxPrice"]],
    "symbol": {
      symbolType: 'image',
      src: "/resources/free.svg",
      "size": [
        "interpolate",
        [
          "exponential",
          2.5
        ],
        [
          "zoom"
        ],
        2,
        2,
        14,
        20
      ],
      // rotation: ['/', ['*',['-', 360, ['get', 'angle']], 3.1412], 180],
      // rotateWithView: true
    }
  };

  const soldStyle = {
    // "variables": {
    //   maxPrice: 1000000
    // },
    // "filter" : ["case", ["<=", ["get", "price"], ["var", "maxPrice"]], ["==", ["get", "status"], "sold"], false],
    // "filter" : ["case", ["==", ["get", "pricePerMonth"], 0], ["==", ["get", "status"], "sold"], false],
    "filter": ["==", ["get", "status"], "sold"],
    // "filter" : ["==", ["get", "price"], 0],
    "symbol": {
      symbolType: 'image',
      src: "/resources/sold.svg",
      "size": [
        "interpolate",
        [
          "exponential",
          2.5
        ],
        [
          "zoom"
        ],
        2,
        2,
        14,
        20
      ],
      // rotation: ['/', ['*',['-', 360, ['get', 'angle']], 3.1412], 180],
      // rotateWithView: true
    }
  };

  const cartStyle = {
    "variables": {
      maxPrice: 1000000
    },
    "filter": ["==", ["get", "inCart"], 1],
    "symbol": {
      symbolType: 'image',
      src: "/resources/incart.svg",
      "size": [
        "interpolate",
        [
          "exponential",
          2.5
        ],
        [
          "zoom"
        ],
        2,
        3,
        14,
        24
      ],
      // rotation: ['/', ['*',['-', 360, ['get', 'angle']], 3.1412], 180],
      // rotateWithView: true
    }
  };

  const curfaceStyle = {
    "symbol": {
      symbolType: 'image',
      src: "/resources/curface.svg",
      "size": [
        "interpolate",
        [
          "exponential",
          2.5
        ],
        [
          "zoom"
        ],
        2,
        3,
        14,
        50
      ],
      rotation: ['/', ['*', ['-', 360, ['get', 'angle']], Math.PI], 180],
      rotateWithView: true
    }
  };

  const facesSource = new olSourceVector({
    format: new olFormatGeoJSON(),
    url: function (extent) { // resolution, projection
      // console.log([extent, resolution, projection]);
      const center = olExtent.getCenter(extent),
        tl = olExtent.getTopLeft(extent),
        radius = olSphere.getDistance(olProj.toLonLat(center), olProj.toLonLat(tl)),
        lonlat = olProj.toLonLat(center),
        lon = lonlat[0], lat = lonlat[1];
      // console.log([lon, lat, radius]);
      return `/api/v1/geoquery?lon=${lon}&lat=${lat}&radius=${radius}`
    },
    strategy: olStrategy.bbox,
  });

  const fl = new MyWebGLPointsLayer({
    // const fl = new olLayerWebGLPoints({
    source: facesSource,
    style: faceStyle,
    disableHitDetection: false,
  });

  const fll = new olLayerVector({
    // unsuccessful attempt to fix labels
    // source: new olSourceVector({
    //   features: []
    // }),
    source: facesSource,
    style: new olStyleStyle({text: new olText({text: ''})}), //createLabelStyle, //getLabelStyle(filterContext),
    declutter: true,
    zIndex: 10
  });

// const sl = new MyWebGLPointsLayer({
  const sl = new olLayerWebGLPoints({
    source: facesSource,
    style: soldStyle,
    disableHitDetection: false,
  });

  // const soldLabelStyle = new olStyleStyle({
  //   text: new olText({
  //     font: '12px Calibri,sans-serif',
  //     overflow: true,
  //     fill: new olFill({
  //       color: '#f00',
  //     }),
  //     stroke: new olStroke({
  //       color: '#fff',
  //       width: 3,
  //     }),
  //     offsetY: -7,
  //     textBaseline: 'bottom',
  //     textAlign: 'left',
  //     text: 'Sold'
  //   }),
  // });

  // const sll = new olLayerVector({
  //   source: facesSource,
  //   style: soldLabelStyle,
  //   declutter: true,
  //   zIndex: 0
  // });

  const cl = new olLayerWebGLPoints({
    source: facesSource,
    style: cartStyle,
    disableHitDetection: false,
  });

  const cfl = new olLayerWebGLPoints({
    source: new olSourceVector({
      features: []
    }),
    style: curfaceStyle,
    disableHitDetection: true,
  });

  // const curPositionStyle = {
  //   "symbol": {
  //     symbolType: 'image',
  //     src: 'resources/current.svg',
  //     size: ['interpolate', ['exponential', 2.5], ['zoom'], 2, 1, 14, 32],
  //     rotateWithView: true,
  //     rotation: ['get', 'heading'],
  //   }
  // };

  const geolocation = new ol.Geolocation({
    // enableHighAccuracy must be set to true to have the heading value.
    trackingOptions: {
      enableHighAccuracy: true,
    },
    tracking: true,
    projection: mapObject.getView().getProjection(),
  });

  const accuracyFeature = new ol.Feature();
  geolocation.on('change:accuracyGeometry', function () {
    accuracyFeature.setGeometry(geolocation.getAccuracyGeometry());
  });

  // geolocation.on('error', function (error) {
  //   Ext.toast('Unable to retrieve your location', 600);
  // });

  const positionFeature = new ol.Feature();

  geolocation.on('change:position', function () {
    const coordinates = geolocation.getPosition();
    if (!positionFeature.getGeometry()) {
      mapObject.getView().setCenter(coordinates);
    }
    positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
    mapObject.currentGeolocation = coordinates;
    // checkGeoPositionInExtent();
  });

  // const checkGeoPositionInExtent = function () {
  //   if (positionFeature && positionFeature.getGeometry()) {
  //     const extent = mapObject.getView().calculateExtent();
  //     return !olExtent.containsCoordinate(extent, positionFeature.getGeometry().getCoordinates());
  //   } else {
  //     return false;
  //   }
  // }

  geolocation.on('change', function () {
    positionFeature.set('heading', geolocation.getHeading() || 0);
  });

  const accuracyLayer = new olLayerVector({
    // map: me.map,
    source: new olSourceVector({
      features: [accuracyFeature]
    }),
  });

  const styleCache = {};
  const createPositionStyle = function (feature) { //, resol
    const angle = feature.get('heading') || 0,
      style = styleCache[angle] || new olStyleStyle({
      image: new olStyleIcon({
        // rotation: (-1) * angle * Math.PI / 180,
        rotation: angle,
        // size: [40, 40],
        src: 'resources/current.svg',
        // src: 'resources/shared/images/geolocation_marker.png',
        rotateWithView: true
      })
    });
    if(!styleCache[angle]){
      styleCache[angle] = style;
    }
    return style;
  };
  // me.curPositionLayer = new ol.layer.WebGLPoints({
  const curPositionLayer = new olLayerVector({
    source: new olSourceVector({
      features: [positionFeature]
    }),
    style: createPositionStyle,
    //   style: me.curPositionStyle,
    // disableHitDetection: true
  });

  mapObject.addLayer(accuracyLayer);
  mapObject.addLayer(curPositionLayer);
  mapObject.addLayer(searchPlaceLayer);
  mapObject.addLayer(sl);
  mapObject.addLayer(fl);
// map.addLayer(sll);
  mapObject.addLayer(fll);
  mapObject.addLayer(cl);
  mapObject.addLayer(cfl);

  // if (onMapClick) {
  //   mapObject.on('singleclick', onMapClick);
  // }
  return {
    map: mapObject,
    facesLayer: fl,
    soldLayer: sl,
    facesLabelLayer: fll,
    cartLayer: cl,
    curFaceLayer: cfl,
    searchPlaceLayer,
    geolocation
  }
}