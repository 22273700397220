export default {
  "text": `<img src="/resources/eb_poster01.png" style="width: 200px;float: right;margin: 5px 0 5px 5px;max-width: calc(100% - 5px);display: inline-block;vertical-align: bottom;z-index: 1;position: relative;overflow: auto;padding: 10px 3px 10px 10px;border: none;height: auto;"/>
<p style="text-align: justify;">The largest outdoor advertising saleshouse in Ukraine, the company <b>BigMedia</b> (part of <b>BigBoard / JCDecaux</b>) has created <b>Easyboard</b>, which aims to make <b>outdoor advertising available</b> for all and change stereotypes in small businesses that it is unrealistic, difficult and expensive.
  <br/>
  <br/>The tool is a simple application that helps to get advertising boards to anyone in just a few clicks in the desired location.
  <br/>
  <br/>It is now easy to buy a board in Kyiv, Lviv, Kharkiv or any other city and on the largest highways of Ukraine. The citylights at reasonable price will allow small businesses to tell customers about themselves.
  <br/>
  <br/><span style="color: #0288D1; font-weight: bold;">The algorithm is simple: choose a board near your business or in another attractive place, and then put it in the cart and send it with your contact information, then wait for a call or message from the sales person.</span>
  <br/>
  <br/>The legal position of the company remains unchanged, according to which all layouts will be carefully checked and must comply with the Law of Ukraine. No information that harms society, offensive or unreliable information will have a chance to get on the <b>Bigmedia</b> board.
  <br/>
  <br/><b>Inexpensive outdoor advertising &ndash; it's a reality due to Easyboard!</b>
  <br/>&nbsp;
  <br/>&nbsp;<span style="font-size: 12px;">*Prices on the site include poster printing and VAT, excluding layout design</span></p>

<p><img src="/resources/eb_poster02.png" style="width: 200px;margin: 5px auto;display: block;float: none;vertical-align: top;position: relative;max-width: 100%;cursor: pointer;padding: 0 1px;height: auto;border: none;"/></p>
<br/>
<p style="font-weight: bold">Ukraine, Kyiv
<br/>Ihoryvska 14a
<br/>044 585 99 77
</p>`}