import React from 'react';
// import config from '../config/index.js';
// import {useState} from "react";

// export default function () {
//   const [period, setPeriod] = useState({
//     start: config.periodLimit.min.add(1, 'month').startOf('month'),
//     end: config.periodLimit.min.add(1, 'month').endOf('month')
//   });
//
//   const [price, setPrice] = useState('');
//   return React.createContext({
//     period,
//     setPeriod,
//     price,
//     setPrice
//   })
// }

export default React.createContext({
    // period: {
    //     start: config.periodLimit.min.add(1, 'month').startOf('month'),
    //     end: config.periodLimit.min.add(1, 'month').endOf('month')
    // },
    // periodChange: (p) => {
    //     // console.log(p)
    // },
    // price: null,
    // priceChange: () => {}
});
