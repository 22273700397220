import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';
import DialogActions from '@mui/material/DialogActions';
// import {useEffect, useState} from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FilterDialog({title, open, setOpen, children, sx, onApplyClick, isValid = true}) {
  // const [isValid, setIsValid] = useState(false);
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const handleApply = () => {
    if (onApplyClick) {
      onApplyClick();
    }
    setOpen(false);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  // const fullScreen = false;

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      sx={sx}
    >
      <AppBar sx={{position: 'relative'}} color={"transparent"}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon/>
          </IconButton>
          <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
            {title}
          </Typography>
          {/*<Button autoFocus color="inherit" onClick={handleClose}>*/}
          {/*    save*/}
          {/*</Button>*/}
        </Toolbar>
      </AppBar>
      {children}
      <DialogActions>
        <Button variant={"contained"} onClick={handleApply} autoFocus fullWidth disabled={!isValid}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}
