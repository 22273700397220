export default {
  "text": `<img src="/resources/eb_poster01.png" style="width: 300px;float: right;margin: 5px 0 5px 5px;max-width: calc(100% - 5px);display: inline-block;vertical-align: bottom;z-index: 1;position: relative;overflow: auto;padding: 10px 3px 10px 10px;border: none;height: auto;"/>
<p style="text-align: justify;">Найбільший сейлсхауз зовнішньої реклами в Україні компанія <b>BigMedia</b> (холдинг <b>BigBoard / JCDecaux</b>) створила <b>Easyboard</b>, мета якого зробити <b>зовнішню рекламу доступною</b> для всіх та змінити стереотипи у малих бізнесів, що це нереально, складно і дорого.
  <br/>
  <br/>Інструмент являє собою простий механізм, завдяки якому будь-яка людина може розміститися всього за декілька кліків в бажаній для себе локації.
  <br/>
  <br/>Купити борд в Києві, Львові, Харкові чи будь-якому іншому місті та на найбільших автомагістралях України тепер легко. Доступні сітілайти дадуть змогу маленьким бізнесам розповісти про себе потенційним клієнтам.
  <br/>
  <br/><span style="color: #0288D1; font-weight: bold;">Механізм роботи простий: оберіть площину поблизу свого бізнесу, чи в іншому привабливому місці, а далі занесіть її до кошика, після чого чекайте дзвінка або повідомлення від менеджера.</span>
  <br/>
  <br/>Незмінною залишається правова позиція компанії, згідно з якою всі макети будуть ретельно перевірені і мають відповідати Закону України &laquo;Про рекламу&raquo;. Жодна інформація, що несе шкоду суспільству, образливу чи недостовірну інформацію не матиме шансів потрапити на площини <b>Bigmedia</b>.
  <br/>
  <br/><b>Зовнішня реклама недорого &ndash; це реальність завдяки Easyboard!</b>
  <br/>&nbsp;
  <br/>&nbsp;<span style="font-size: 12px;">*Ціни на сайті вказані з урахуванням друку постерів та ПДВ, без урахування дизайну макета</span></p>

<p><img src="/resources/eb_poster02.png" style="width: 300px;margin: 5px auto;display: block;float: none;vertical-align: top;position: relative;max-width: 100%;cursor: pointer;padding: 0 1px;height: auto;border: none;"/></p>
<br/>
<p style="font-weight: bold">Україна, Київ
<br/>вул. Ігорівська 14a
<br/>044 585 99 77
</p>`}