import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';
import {useNavigate} from "react-router-dom";
import i18n from "../utils/i18n";
import {FormHelperText, Input, InputLabel, MobileStepper, Snackbar, Table, TextField} from "@mui/material";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FaceContext from "../Contexts/FaceContext";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import {getPeriodText} from "../utils";
import PropTypes from "prop-types";
import {useFormik} from 'formik';
import * as yup from "yup";
import {IMaskInput} from "react-imask";
import FormControl from "@mui/material/FormControl";
import DeleteIcon from '@mui/icons-material/Delete';
import {Alert} from '@mui/material';
import DayJS from "dayjs";
import DialogActions from "@mui/material/DialogActions";
import utc from 'dayjs/plugin/utc';

DayJS.extend(utc);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// const validationRe = {
//   name: /\w+/,
//   email: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
//   phone: /\+?\d{1,3}\(?\d{2,3}\)?\d{3}\-?\d{2}\-?\d{2}/
// }

const phoneRegExp = /^\+?\d{1,3}\(?\d{2,3}\)?\d{3}-?\d{2}-?\d{2}$/;
// /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  name: yup
    .string('Enter your name')
    .required('Name is required'),
  phone: yup
    .string('Enter your phone')
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Phone is required')
});

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const {onChange, ...other} = props;
  return (
    <IMaskInput
      {...other}
      mask="+{\3\8\0}(00)000-00-00"
      // mask={/^\+?\d{1,3}\(?\d{2,3}\)?\d{3}\-?\d{2}\-?\d{2}$/}
      // mask={[
      //   {
      //     mask: "+{\\3\\8\\0}(00)000-00-00"
      //   },
      //   // {
      //   //   mask: /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/
      //   // }
      // ]}
      // definitions={{
      //   '#': /[1-9]/,
      // }}
      inputRef={ref}
      onAccept={(value) => onChange({target: {name: props.name, value}})}
      // overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function Checkout({sx}) {
  const [activeStep, setActiveStep] = useState(0);
  // const [values, setValues] = useState({name: '', email: '', phone: ''});
  // const [isFormValid, setIsFormValid] = useState(false);
  // const [error, setError] = useState({name: false, email: false, phone: false});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');
  const [openSuccess, setOpenSuccess] = useState(false);
  const navigate = useNavigate();

  const now = new DayJS();
  const isWorkingHours = now.day() >= 1 && now.day() <= 5 && now.hour() >= 8 && now.hour() <= 19;

  // useEffect(() => {
  //   // console.log(error);
  //   const valid = Object.values(error).reduce((r, e) => r && !e, true) && !!values.name && !!values.email && !!values.phone;
  //   // console.log(valid);
  //   setIsFormValid(valid);
  // }, [error]);

  const handleClose = () => {
    navigate('/');
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleOkClick = () => {
    setOpenSuccess(false);
    faceContext.setCart([]);
  }

  const handleSuccessClose = () => {
    // navigate('/');
    setOpenSuccess(false);
    faceContext.setCart([]);
  }

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const faceContext = useContext(FaceContext);

  useEffect(() => {
    if (!faceContext.cart || !faceContext.cart.length) {
      navigate('/');
    }
  });

  const handleNext = () => {
    if (activeStep === 1) {
      formik.submitForm();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleChange = (event) => {
  //   setValues({
  //     ...values,
  //     [event.target.name]: event.target.value,
  //   });
  //   setError({...error, [event.target.name]: !validationRe[event.target.name].test(event.target.value)});
  // };

  const prepareParams = function (values) {
    const params = {...values, faces: []};
    faceContext.cart.forEach((face) => {
      // console.log([face.startDate.startOf('date').utcOffset(0, true).format(),
      //   face.endDate.startOf('date').utcOffset(0, true).format()])
      params.faces.push({
        faceId: face.id,
        startDate: face.startDate.startOf('date').utcOffset(0, true).format(),
        endDate: face.endDate.startOf('date').utcOffset(0, true).format(),
        price: face.price,
        printCost: face.printCost,
        deliveryCost: face.deliveryCost,
        createdAt: face.createdAt,
        city: i18n.t('refCity.'+face.id_city, {lng: 'uk-UA'}),
        address: face['address_' + i18n.t('addressSuffix', {lng: 'uk-UA'})],
        network: i18n.t('refNetwork.'+face.id_network, {lng: 'uk-UA'}),
        size: i18n.t('refSize.'+face.id_size, {lng: 'uk-UA'}),
        periodText: getPeriodText(face.startDate, face.endDate, 'uk-UA'),
        total: face.total
      });
    });
    params.total = faceContext.cart.reduce((res,item) => (res + item.total), 0);
    return params;
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
      phone: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const data = prepareParams(values);
      fetch('/api/v1/easyboard/checkout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }).then((response)=>{
        if (!response || !response.ok) {
          // this.getView().close();
          setAlertSeverity('error');
          setAlertMessage(i18n.t('checkoutSaveErrorMessage'));
          setOpenSnackbar(true);
        } else {
          // faceContext.setCart([]);
          setOpenSuccess(true);
          // EasyBoard.util.Shared.facebookQuery('track', 'Purchase', {
          //   content_ids: ids,
          //   content_type: 'product',
          //   value: p.total
          // });
        }
      });
    },
  });

  return (
    <>
    <Dialog
      fullScreen={fullScreen}
      onClose={handleClose}
      open
      TransitionComponent={Transition}
      sx={{...sx}}
    >
      <AppBar sx={{position: 'relative'}} color={"transparent"}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon/>
          </IconButton>
          <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
            {i18n.t('checkoutTitle')}
          </Typography>
          {/*<Button autoFocus color="inherit" onClick={handleClose}>*/}
          {/*    save*/}
          {/*</Button>*/}
        </Toolbar>
      </AppBar>
      <div style={{display: 'block', width: '100%', padding: '8px 10px 5px 10px'}}>
        <div style={{float: 'left', fontSize: 10, fontWeight: 400}}>
          {i18n.t('boards', {count: faceContext.cart.length})}
        </div>
        <div style={{float: 'right', fontWeight: 600, fontSize: 12}}>
          {faceContext.cart.reduce((res, c) => {
            return res + c.total;
          }, 0)} &nbsp;₴<sup>*</sup>
        </div>
      </div>
      <TableContainer sx={{flex: 1}} hidden={activeStep !== 0}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
            </TableRow>
          </TableHead>
          <TableBody>
            {faceContext.cart
              .map((item, ix) => {
                // const row = item;
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={ix} onClick={() => navigate('/faces/' + item.id)}>
                    <TableCell key='col1' align="left">
                      <div style={{
                        width: 100,
                        height: 80,
                        background: `center / contain no-repeat url(https://bma.bigmedia.ua/photohub/face/${item.id})`
                      }}/>
                    </TableCell>
                    <TableCell key='col2' align="left">
                      <div className="face-address">
                        {/*{faceContext.face && faceContext.face.status}&nbsp;*/}
                        {item['address_' + i18n.t('addressSuffix')]}
                      </div>
                      <div className="face-params">
                        {i18n.t('refNetwork.' + item.id_network)}
                        &nbsp;•&nbsp;
                        {i18n.t('refSize.' + item.id_size)}
                      </div>
                    </TableCell>
                    <TableCell key='col3' align="left">
                      <div style={{fontSize: 12}}>
                        {getPeriodText(item.startDate, item.endDate, i18n.language)}
                      </div>
                      <div style={{fontWeight: 600}}>
                        {item.total + ' ₴'}
                      </div>
                    </TableCell>
                    <TableCell key='col4' align='right'>
                      <IconButton
                        // className='table-button'
                        onClick={(e) => {
                          e.stopPropagation();
                          const newCart = [...faceContext.cart];
                          newCart.splice(ix, 1);
                          faceContext.setCart(newCart);
                        }}
                      >
                        <DeleteIcon/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="checkout-form" hidden={activeStep !== 1} style={{padding: 10, flex: 1}}>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            autoFocus
            // error={error.name}
            margin="dense"
            id="name"
            name="name"
            label={i18n.t('nameText')}
            fullWidth
            variant="standard"
            // value={values.name}
            // onChange={handleChange}
            // required
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            required
          />
          <TextField
            // error={error.email}
            margin="dense"
            id="email"
            label={i18n.t('emailText')}
            name="email"
            type="email"
            fullWidth
            variant="standard"
            // value={values.email}
            // onChange={handleChange}
            // required
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            required
          />
          <FormControl
            variant="standard" fullWidth required
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            onChange={formik.handleChange}
            margin="dense"
          >
            <InputLabel htmlFor="phone" variant="standard" filled>{i18n.t('phoneText')}</InputLabel>
            <Input
              name="phone"
              id="phone"
              type="tel"
              inputComponent={TextMaskCustom}
              fullWidth
              value={formik.values.phone}
              required
            />
            <FormHelperText>{formik.touched.phone && formik.errors.phone}</FormHelperText>
          </FormControl>
        </form>
      </div>
      <div style={{fontSize: 9, paddingLeft: 10}}>
        {i18n.t('checkoutFootnote')}
        {activeStep ? <br/> : ''}
        {activeStep ? i18n.t('checkoutFootnote2') : ''}
      </div>
      <MobileStepper
        variant="dots"
        steps={2}
        position="static"
        activeStep={activeStep}
        // sx={{maxWidth: 600}}
        nextButton={
          <Button size="small" onClick={handleNext}>
            {(activeStep === 0) ? i18n.t('nextButtonText') : i18n.t('checkoutButtonText')}
            {(activeStep === 0) ? <KeyboardArrowRight/> : ''}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            <KeyboardArrowLeft/>
            {i18n.t('backButtonText')}
          </Button>
        }
      />
    </Dialog>
    <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
      <Alert onClose={handleCloseSnackbar} variant="filled" severity={alertSeverity} sx={{ width: '100%' }}>
        {alertMessage}
      </Alert>
    </Snackbar>
    <Dialog open={openSuccess} fullScreen={fullScreen} onClose={handleSuccessClose}>
      <div style={{margin: 'auto'}}>
        <img alt="saved" src="/resources/saved.png" height={280}/>
        <div style={{textAlign: "center"}}>
          <p style={{fontSize: 15, lineHeight: '24px', fontWeight: 500, color: "#111"}}>
            {i18n.t('thankyouText')}
          </p>
          <p style ={{fontSize: 13, lineHeight: "150%"}}>
            {isWorkingHours ? i18n.t('managerShortlyText') : i18n.t('managerSoonText')}
          </p>
        </div>
      </div>
      <DialogActions>
        <Button variant={"contained"} onClick={handleOkClick} autoFocus fullWidth={fullScreen}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
    </>
  );
}