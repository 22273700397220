import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import aboutUA from './i18n/about_uk-UA.js';
import aboutEN from './i18n/about_en-US.js';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en-US',
    detection: {
      order: ["localStorage", "querystring", "navigator"],
      lookupQuerystring: 'lang'
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      "en-US": {
        translation: {
          searchEditPlaceholder: 'Search street',
          dialogPeriodTitle: 'Select period',
          dialogPriceTitle: 'Input max. price',
          buttonPriceText: 'Price',
          year_one: 'year',
          year_other: 'years',
          addressSuffix: 'en',
          refCity: {
            1: 'Kyiv',
            3: 'Kharkiv',
            4: 'Lviv',
            5: 'Odessa',
            6: 'Zaporizhya',
            10: 'Dnipro',
            11: 'Kryviy Rig',
            12: 'Ivano-Frankivsk',
            13: 'Lutsk',
            14: 'Sumy',
            15: 'Chenivtsi',
            19: 'Kherson',
            20: 'Khmelnitskiy',
            21: 'Kremenchug',
            22: 'Mykolayiv',
            23: 'Poltava',
            24: 'Rivne',
            25: 'Ternopil',
            26: 'Vinnytsya',
            29: 'Zhitomir',
            30: 'Kropyvnytskiy',
            31: 'Uzhgorod',
            32: 'Mariupil',
            33: 'Kyiv Region',
            36: 'Chernigiv',
            38: 'Cherkassy',
            39: 'Bila Tserkva',
            41: 'Kramatorsk',
            42: 'Konstantynivka',
            43: 'Illichivsk',
            48: 'Slavyansk',
            49: 'Pokrovsk',
            50: 'Pavlograd',
            51: 'Kamyanske',
            54: 'Kyiv Routes',
            56: 'Boryspil',
            57: 'Melitopol',
            58: 'Kamianets-Podilskyi',
            59: 'Berdiansk',
            60: 'Chop',
            61: 'Mukachevo',
            62: 'Kalush',
            63: 'Shepetivka',
            64: 'Oleksandriia',
            65: 'Kovel',
            66: 'Drohobych',
            67: 'Truskavets',
            69: 'Berdychiv',
            70: 'Lubny',
            71: 'Romny',
            72: 'Pryluky',
            73: 'Svitlovodsk',
            74: 'Bershad',
            75: 'Bratslav',
            76: 'Wapnyarka',
            77: 'Hnyvan',
            78: 'Zhmerynka',
            79: 'Illyintsi',
            80: 'Kalynivka',
            81: 'Kryzhopil',
            82: 'Ladyzhin',
            83: 'Luka-Meleshkovska',
            84: 'Mohyliv-Podilskii',
            85: 'Orativ',
            86: 'Pohrebische',
            87: 'Tyvriv',
            88: 'Tomashpil',
            89: 'Trostyanets',
            90: 'Tulchyn',
            91: 'Turbov',
            92: 'Shargorod',
            93: 'Shpykiv',
            94: 'Bezpillya',
            95: 'Borislav',
            96: "Brody",
            97: "Varash (Kuznetsovs'k)",
            98: "Dubno",
            99: "Zdolbuniv",
            100: "Kolomiya",
            101: "Korosten",
            102: "Kremenets",
            103: "Lviv Routes",
            104: "Malin",
            105: "Mogiliv-Podilsky",
            106: "Nikopol",
            107: "Nova Kakhovka",
            108: "Novovolynsk",
            109: "Odessa Routes",
            110: "Piryatin",
            111: "Putivl",
            112: "Rava-Ruska",
            113: "Sarni",
            114: "Smila",
            115: "Khorol",
            116: "Chervonograd",
            117: "Tyachiv",
            118: "Beregovo",
            119: "Bilopillya",
            120: "Lebedyn",
            121: "Nedrygayliv",
            122: "Okhtyrka",
            123: "Shostka",
            124: "Korostyshiv",
            126: 'Chortkiv',
            128: 'Bakhmut',
            129: 'Lysychansk',
            130: 'Pokrovsk',
            131: 'Severodonetsk',
            132: 'Stryy',
            133: 'Morshyn',
            134: 'Sambir',
            135: 'Slavske',
            136: 'Marhanets',
            138: 'Vilnohirsk',
            139: 'Vasylkivka',
            140: 'Kharkiv routes',
            141: 'Slavuta',
            142: 'Khmelnitsky routes',
            143: 'Tysmenytsya',
            144: 'Bukovell',
            145: 'Yaremche',
            146: 'Nadvirna',
            147: 'Bohorodchany',
            148: 'Bolehiv',
            149: 'Burshtyn',
            150: 'Ivano-Frankivsk routes',
            151: 'Halych',
            152: 'Dolyna',
            153: 'Rogatyn',
            154: 'Kosiv',
            155: 'Snyatyn',
            156: 'Horodenka',
            157: 'Vorokhta',
            158: 'Slavutych',
            159: 'Nizhyn',
            160: 'Verkhovyna',
            161: 'Tlumach',
            162: 'Horyshni Plavni',
            163: 'Poltava routes'
          },
          refAB: {
            1: 'A',
            2: 'B'
          },
          refNetwork: {
            522: 'Bus Shelter',
            487: 'Citylight',
            488: 'Scroll',
            489: 'Backlight',
            490: 'Bridge',
            491: 'Nonstandard',
            492: 'Prizmavision',
            493: 'Board',
            541: 'Digital'
          },
          refSize: {
            1: '3x6',
            2: 'Nonstandard',
            3: '3x12',
            4: '5x12',
            5: '3x4',
            6: '4x10',
            7: '4x8',
            8: '6x3',
            9: '1.5x2.4',
            10: '3x11',
            11: '2x5',
            12: '7x4',
            13: '1.2x1.8',
            14: '2.3x3.5',
            15: '6x12',
            26: '2x21',
            27: '2x24',
            28: '3x24',
            29: '1.5x6',
            20: '1.5x3',
            31: '1.2x3.6',
            32: '3x21',
            33: '3x18',
            34: '2.3x3.14',
            25: '3x3',
            35: '2.6x1.17',
            36: '8x4',
            37: '1.8x1.2',
            38: '2.097x1.263',
            39: '4x12',
            40: '2x16',
            41: '2x4',
            42: '2x8',
            43: '1.2x1.7',
            44: '2x3'
          },
          refSupplier: {
            1: 'BIGMEDIA',
            20410: 'ДНІПРОВІЖН',
            20509: 'СВ АУТДОР',
            20575: 'ЛУВЕРС',
            20562: 'СЕАН',
            20506: 'БІОФОРС',
            20512: 'ГАЛИЦЬКІ КОНТРАКТИ',
            20582: 'САС',
            20583: 'ВІАЛ МЕДІА',
            20586: 'БОНА МЕНТЕ',
            20588: 'A-PETROL',
            20591: 'ПЕРЕХІД АУТДОР ДП',
            // 20595: 'CITY MEDIA GROUP',
            20605: 'НАРУЖКА',
            20685: 'РТМ-УКРАЇНА',
            20777: 'КОММЕРЦ ЕВОЛЮШН',
            20754: 'РЕКЛАМА СЕРВІС',
            21605: 'ЗГІА',
            21639: 'ЕЛІЗІЯ',
            21657: 'GOLD FUTURE',
            23168: 'ДОВІРА АУТДОР',
            23288: 'СІТІ-РТМ',
            23349: 'АЛЬХОР АУТДОР',
            23562: 'СТ-РЕКЛАМА',
            23730: 'БУМЕРАНГ',
            24377: 'БІЛЛБОРД',
            24384: 'ФАБРИКА РЕКЛАМИ',
            24410: 'ДИКИЙ ЗАХІД', //Дикий Запад
            24818: 'КРАШ',
            25124: 'БОРДИ УКРАЇНИ МЕДІА',
            25957: 'ТЕХНОПАРК',
            25969: 'БОЙКО ФОП',
            25979: 'БІГ-ІНФО',
            26377: 'АРТ РАДІО',
            26529: 'НАМЕНАНІК ФОП'
          },
          faceDetailAddButtonLabel: 'Buy',
          hryvniaPerMonth: '₴/month',
          soldStatus: 'Sold',
          faceDetailSendMessageLabel: "Send message",
          faceDetailCheckoutButtonLabel: "Checkout",
          checkoutTitle: 'Checkout',
          boards_one: '{{count}} board',
          boards_many: '{{count}} boards',
          boards_other: '{{count}} boards',
          checkoutFootnote: "* Prices include poster printing and taxes, excluding layout design",
          nextButtonText: 'Next',
          checkoutButtonText: 'Checkout',
          backButtonText: 'Back',
          checkoutFootnote2: "** Required fields",
          sendMessageTitle: 'Send message',
          sendMessageInitialNote: "For what period is this board available and what is its price?",
          sendMessageNoteLabel: "Your question",
          sendMessageButtonText: "Send",
          nameText: 'Name',
          emailText: 'Email',
          phoneText: 'Phone',
          aboutMenuItemText: 'About Us',
          aboutTitle: 'About Us',
          aboutText: aboutEN.text,
          checkoutSaveErrorMessage: 'There was an error sending the data. Please check your internet connection and' +
            ' try again',
          thankyouText: 'Thank You!',
          managerShortlyText: "The sales person will contact you shortly",
          managerSoonText: "The sales person will contact you as soon as possible",
          pricePerMonthLabel: "UAH per month",
          upToPriceText: "{{price}} ₴/mon"
        },
      },
      'uk-UA': {
        translation: {
          searchEditPlaceholder: 'Пошук адреси',
          dialogPeriodTitle: 'Виберіть період',
          dialogPriceTitle: 'Вкажіть граничну ціну',
          buttonPriceText: 'Ціна',
          year_one: 'рік',
          year_other: 'роки',
          addressSuffix: 'ukr',
          refCity: {
            1: 'Київ',
            3: 'Харків',
            4: 'Львів',
            5: 'Одеса',
            6: 'Запоріжжя',
            10: 'Дніпро',
            11: 'Кривий Ріг',
            12: 'Івано-Франківськ',
            13: 'Луцьк',
            14: 'Суми',
            15: 'Чернівці',
            19: 'Херсон',
            20: 'Хмельницький',
            21: 'Кременчуг',
            22: 'Миколаїв',
            23: 'Полтава',
            24: 'Рівне',
            25: 'Тернопіль',
            26: 'Вінниця',
            29: 'Житомир',
            30: 'Кропивницький',
            31: 'Ужгород',
            32: 'Маріуполь',
            33: 'Київська область',
            36: 'Чернігів',
            38: 'Черкаси',
            39: 'Біла Церква',
            41: 'Краматорськ',
            42: 'Костянтинівка',
            43: 'Іллічівськ',
            48: 'Слов\'янськ',
            49: 'Покровськ',
            50: 'Павлоград',
            51: 'Кам\'янське',
            54: 'Траси Києва',
            56: 'Бориспіль',
            57: 'Мелітополь',
            58: 'Кам\'янець-Подільський',
            59: 'Бердянськ',
            60: 'Чоп',
            61: 'Мукачево',
            62: 'Калуш',
            63: 'Шепетівка',
            64: 'Олександрія',
            65: 'Ковель',
            66: 'Дрогобич',
            67: 'Трускавець',
            69: 'Бердичів',
            70: 'Лубни',
            71: 'Ромни',
            72: 'Прилуки',
            73: 'Світловодськ',
            74: 'Бершадь',
            75: 'Брацлав',
            76: 'Вапнярка',
            77: 'Гнивань',
            78: 'Жмеринка',
            79: 'Іл\'їнці',
            80: 'Калинівка',
            81: 'Крижопіль',
            82: 'Ладижин',
            83: 'Лука-Мелешковська',
            84: 'Могилів-Подільский',
            85: 'Оратів',
            86: 'Погребище',
            87: 'Тиврів',
            88: 'Томашпіль',
            89: 'Тростянець',
            90: 'Тульчин',
            91: 'Турбов',
            92: 'Шаргород',
            93: 'Шпиків',
            94: "Безпілля",
            95: "Борислав",
            96: "Броди",
            97: "Вараш (Кузнецовськ)",
            98: "Дубно",
            99: "Здолбунів",
            100: "Коломия",
            101: "Коростень",
            102: "Кременець",
            103: "Львів траси",
            104: "Малин",
            105: "Могилів-Подільський",
            106: "Нікополь",
            107: "Нова Каховка",
            108: "Нововолинськ",
            109: "Одеса траси",
            110: "Пирятин",
            111: "Путивль",
            112: "Рава-Руська",
            113: "Сарни",
            114: "Сміла",
            115: "Хорол",
            116: "Червоноград",
            117: "Тячів",
            118: "Берегово",
            119: "Білопілля",
            120: "Лебедин",
            121: "Недригайлів",
            122: "Охтирка",
            123: "Шостка",
            124: "Коростишів",
            126: 'Чортків',
            128: 'Бахмут',
            129: 'Лисичанськ',
            130: 'Покровськ',
            131: 'Сєвєродонецьк',
            132: 'Стрий',
            133: 'Моршин',
            134: 'Самбір',
            135: 'Славське',
            136: 'Марганець',
            138: 'Вільногірськ',
            139: 'Васильківка',
            140: 'Траси Харкова',
            141: 'Славута',
            142: 'Траси Хмельницького',
            143: 'Тисмениця',
            144: 'Буковель',
            145: 'Яремче',
            146: 'Надвірна',
            147: 'Богородчани',
            148: 'Болехів',
            149: 'Бурштин',
            150: 'Траси Івано-Франківська',
            151: 'Галич',
            152: 'Долина',
            153: 'Рогатин',
            154: 'Косів',
            155: 'Снятин',
            156: 'Городенка',
            157: 'Ворохта',
            158: 'Славутич',
            159: 'Ніжин',
            160: 'Верховина',
            161: 'Тлумач',
            162: 'Горишні Плавні',
            163: 'Траси Полтави'
          },
          refNetwork: {
            522: 'Зупинка',
            487: 'Сітілайт',
            488: 'Скрол',
            489: 'Беклайт',
            490: 'Міст',
            491: 'Нестандарт',
            492: 'Призмавіжн',
            493: 'Щит',
            541: 'Digital'
          },
          refAB: {
            1: 'A',
            2: 'B'
          },
          refSize: {
            1: '3x6',
            2: 'Нестандарт',
            3: '3x12',
            4: '5x12',
            5: '3x4',
            6: '4x10',
            7: '4x8',
            8: '6x3',
            9: '1.5x2.4',
            10: '3x11',
            11: '2x5',
            12: '7x4',
            13: '1.2x1.8',
            14: '2.3x3.5',
            15: '6x12',
            26: '2x21',
            27: '2x24',
            28: '3x24',
            29: '1.5x6',
            20: '1.5x3',
            31: '1.2x3.6',
            32: '3x21',
            33: '3x18',
            34: '2.3x3.14',
            25: '3x3',
            35: '2.6x1.17',
            36: '8x4',
            37: '1.8x1.2',
            38: '2.097x1.263',
            39: '4x12',
            40: '2x16',
            41: '2x4',
            42: '2x8'
          },
          refSupplier: {
            1: 'BIGMEDIA',
            20410: 'ДНІПРОВІЖН',
            20509: 'СВ АУТДОР',
            20575: 'ЛУВЕРС',
            20562: 'СЕАН',
            20506: 'БІОФОРС',
            20512: 'ГАЛИЦЬКІ КОНТРАКТИ',
            20582: 'САС',
            20583: 'ВІАЛ МЕДІА',
            20586: 'БОНА МЕНТЕ',
            20588: 'A-PETROL',
            20591: 'ПЕРЕХІД АУТДОР ДП',
            // 20595: 'CITY MEDIA GROUP',
            20605: 'НАРУЖКА',
            20685: 'РТМ-УКРАЇНА',
            20777: 'КОММЕРЦ ЕВОЛЮШН',
            20754: 'РЕКЛАМА СЕРВІС',
            21605: 'ЗГІА',
            21639: 'ЕЛІЗІЯ',
            21657: 'GOLD FUTURE',
            23168: 'ДОВІРА АУТДОР',
            23288: 'СІТІ-РТМ',
            23349: 'АЛЬХОР АУТДОР',
            23562: 'СТ-РЕКЛАМА',
            23730: 'БУМЕРАНГ',
            24377: 'БІЛЛБОРД',
            24384: 'ФАБРИКА РЕКЛАМИ',
            24410: 'ДИКИЙ ЗАХІД', //Дикий Запад
            24818: 'КРАШ',
            25124: 'БОРДИ УКРАЇНИ МЕДІА',
            25957: 'ТЕХНОПАРК',
            25969: 'БОЙКО ФОП',
            25979: 'БІГ-ІНФО',
            26377: 'АРТ РАДІО',
            26529: 'НАМЕНАНІК ФОП'
          },
          faceDetailAddButtonLabel: 'Додати',
          hryvniaPerMonth: '₴/місяць',
          soldStatus: 'Зайнятий',
          faceDetailSendMessageLabel: "Зв'язатися з менеджером",
          faceDetailCheckoutButtonLabel: "Оформити",
          checkoutTitle: 'Оформлення',
          boards_one: '{{count}} борд',
          boards_few: '{{count}} борди',
          boards_many: '{{count}} бордів',
          boards_other: '{{count}} бордів',
          checkoutFootnote: "* Ціни вказані з урахуванням друку плаката та ПДВ, без урахування дизайну макета",
          nextButtonText: 'Далі',
          checkoutButtonText: 'Оформити',
          backButtonText: 'Назад',
          checkoutFootnote2: "** Обов'язкові поля",
          sendMessageTitle: 'Відправити повідомлення',
          sendMessageInitialNote: "На які дати доступна ця площина і яка її ціна?",
          sendMessageNoteLabel: "Ваше питання",
          sendMessageButtonText: "Відправити",
          nameText: "Ім'я",
          emailText: 'Email',
          phoneText: 'Телефон',
          aboutMenuItemText: 'Про нас',
          aboutTitle: 'Про нас',
          aboutText: aboutUA.text,
          checkoutSaveErrorMessage: "Виникла помилка при відправці даних. Перевірте з'єднання з Інтернетом та" +
            " повторіть спробу",
          thankyouText: 'Дякуємо!',
          managerShortlyText: "Менеджер зв’яжеться з вами зовсім скоро",
          managerSoonText: "Менеджер зв’яжеться з вами в найближчий робочий час",
          pricePerMonthLabel: "₴/місяць",
          upToPriceText: "до {{price}} ₴/міс"
        }
      }
    }
  });

export default i18n;