import DayJS from "dayjs";
import i18n from "./i18n";

export const capitalize = ([ first, ...rest ], locale = navigator.language) =>
  first.toLocaleUpperCase(locale) + rest.join('')

export function getPeriodText (startDate, endDate, locale = navigator.language) {
  if (!startDate || !endDate) { return ''}
  if (!DayJS(startDate).isValid() || !DayJS(endDate).isValid()) {
    return startDate + ' - ' + endDate;
  }
  let res;
  const start = DayJS(startDate).startOf('date'),
    end = DayJS(endDate).startOf('date'),
    sY = start.year(),
    sM = start.month(),
    sD = start.date(),
    eY = end.year(),
    eM = end.month(),
    eD = end.date(),
    sameY = (sY === eY),
    sameM = (sM === eM),
    isMonthStart = DayJS(start).isSame(DayJS(start).startOf('month'), 'date'),
    isMonthEnd = DayJS(end).isSame(DayJS(end).endOf('month'), 'date');
  if (sD === 1 && sM === 0 && eD === 31 && eM === 11) {
    res = sY.toString();
    if (sameY) {
      res += ' ' + i18n.t('year', {count: 1});
    } else {
      res += ' - ' + eY.toString() + ' ' + i18n.t('year', {count: eY - sY + 1});
    }
  } else {
    let sFormat, eFormat;
    if (!isMonthStart || !isMonthEnd) {
      sFormat = 'DD';
      eFormat = 'DD';
      if (!sameY || !sameM) {
        sFormat += ' MMM';
        if (!sameY) {
          sFormat += ' \'YY';
        }
      }
      eFormat += ' MMMM \'YY';
    }
    else {
      if (!sameY || !sameM) {
        sFormat = 'MMM';
        eFormat = 'MMM';
        if (!sameY) {
          sFormat += '\'YY';
        }
      } else {
        eFormat = 'MMMM';
      }
      eFormat += '\'YY';
    }
    res = (sFormat ? capitalize(DayJS(start).locale(locale).format(sFormat), locale) + ' - ' : '');
    res += capitalize(DayJS(end).locale(locale).format(eFormat), locale);
  }
  return res;
}

export function calcTotalByPeriod (params) {
  const {price, startDate, endDate} = params; //, printCost, deliveryCost
  if (!price || !startDate || ! endDate) {
    return null;
  }
  const sDate = DayJS(startDate).get('date');
  // const eDate = DayJS(endDate).get('date');
  let tempDate = DayJS(startDate).clone();
  let total = 0;
  if (sDate > 1 && sDate <= 6) {
    total = Math.round(price * (tempDate.endOf('month').get('date') - sDate + 1) / tempDate.endOf('month').get('date'))
    tempDate = DayJS(startDate).add(1, 'month').startOf('month');
    // total += printCost + deliveryCost;
  } else if (sDate > 6) {
    total = Math.round(price * (tempDate.endOf('month').get('date') - 7 + 1) / tempDate.endOf('month').get('date'))
    tempDate = DayJS(startDate).add(1, 'month').startOf('month');
    // total += printCost + deliveryCost;
  }
  const months = DayJS(endDate).add(1, 'day').diff(tempDate, 'month');
  // console.log([months, price, printCost, deliveryCost]);
  total += months * (price); // + printCost + deliveryCost
  // if (months > 1) {
  //   console.log([months, price, startDate, endDate, total]);
  // }
  return Math.round(total);
}