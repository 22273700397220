import DayJS from "dayjs";

const date = new Date();
const [m, d, y] = [date.getMonth(), date.getDate(), date.getFullYear()];
const minDate = DayJS(`${y}-${m + 1}-${d}`, 'YYYY-M-D').add(1, 'day');
const maxDate = m < 9 ? DayJS(`${y}-12-31`, 'YYYY-M-D') : DayJS(`${y + 1}-12-31`, 'YYYY-M-D');
const config = {
  periodLimit: {
    min: minDate,
    max: maxDate
  },
  // googleMapsApiKey: "AIzaSyAavpJy_WExK0csPfxM6oS4x26GUUZLsRg" // key for local test
  googleMapsApiKey: "AIzaSyBBNPMBsPLMRvLEVpZvG9e29uAZmEM7QwA"
}

export default config;