import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react"
import './Map.css';
import 'ol/ol.css';
import {Zoom} from 'ol/control';
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from '@mui/material/styles';
import FaceContext from "../Contexts/FaceContext";
import FilterContext from "../Contexts/FilterContext";
import {useNavigate} from "react-router-dom";
import MapObject from "./Map/MapObject";
import {calcTotalByPeriod} from "../utils";
import olStyleStyle from "ol/style/Style";
import olText from "ol/style/Text";
import olFill from "ol/style/Fill";
import olStroke from "ol/style/Stroke";
import * as olProj from "ol/proj";
import * as olExtent from "ol/extent";
import {Point} from "ol/geom";
import {Feature} from "ol";
import {Snackbar} from "@mui/material";
import {Alert} from '@mui/material'

const zoomControl = new Zoom();

const mapObject = MapObject({});

// function useTraceUpdate(props) {
//   const prev = useRef(props);
//   useEffect(() => {
//     const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
//       if (prev.current[k] !== v) {
//         ps[k] = [prev.current[k], v];
//       }
//       return ps;
//     }, {});
//     if (Object.keys(changedProps).length > 0) {
//       console.log('Changed props:', changedProps);
//     }
//     prev.current = props;
//   });
// }

const Map = ({searchPlace}) => { //setMapObject,
  const mapRef = useRef();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const faceContext = useContext(FaceContext);
  const filterContext = useContext(FilterContext);
  const navigate = useNavigate();
  const setFaces = faceContext.setFaces;

  const handleCloseSnackbar = function () {
    setOpenSnackbar(false);
  }

  const handleGeolocationError = function () {
    setOpenSnackbar(true);
  }

  const handleMapClick = (evt) => {
    const pixel = evt.pixel;
    if (!mapObject.map.hasFeatureAtPixel(pixel, {
      layerFilter: (layer) => (layer === mapObject.facesLayer) || (layer === mapObject.soldLayer) || (layer === mapObject.cartLayer),
      hitTolerance: 10
    })) {
      navigate('');
      faceContext.setFace(null);
      return;
    }
    mapObject.map.forEachFeatureAtPixel(pixel, (feature) => {
      navigate('/faces/' + feature.getId());
      return true;
    }, {
      layerFilter: (layer) => (layer === mapObject.facesLayer) || (layer === mapObject.soldLayer) || (layer === mapObject.cartLayer),
      hitTolerance: 10
    });
  };

  const getLabelText = useCallback(function (f) {
    const fcp = filterContext.price;
    const {pricePerMonth, total} = faceContext.faces[f.getId()] || f.getProperties();
    if (!pricePerMonth || !total || (fcp && pricePerMonth > fcp) || f.get('status') === 'sold') {
      return null;
    }
    if (faceContext.faces && faceContext.faces[f.getId()]) {
      return faceContext.faces[f.getId()].total + '₴';
    }
    return f.get('total').toString() + '₴';
  }, [faceContext.faces, filterContext.price]);

  const createLabelStyle = useCallback(function (f, resolution) {
    const fcp = filterContext.price;
    if (!f.get('pricePerMonth') || !f.get('total') || (fcp && f.get('pricePerMonth') > fcp)) {
      return null;
    }
    return new olStyleStyle({
      text: new olText({
        font: '12px Calibri,sans-serif',
        overflow: true,
        fill: new olFill({
          color: '#000',
        }),
        stroke: new olStroke({
          color: '#fff',
          width: 3,
        }),
        offsetY: -7,
        textBaseline: 'bottom',
        textAlign: 'left',
        text: getLabelText(f, resolution)
      })
    })
  }, [filterContext.price, getLabelText]);

  const freeRe = useMemo(() => {
    if (!filterContext || !filterContext.period) {
      return;
    }
    const startDate = filterContext.period.start,
      endDate = filterContext.period.end,
      wholePeriod = false,
      minFreeDays = 15,
      allowTempRes = true,
      now = new Date(),
      // minDate = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate() + 1));
      minDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);

    const daysToBeg = Math.round((startDate - minDate) / (3600 * 24 * 1000)),
      daysPeriod = Math.round((endDate - startDate) / (3600 * 24 * 1000));

    if (daysPeriod < 0) {
      return;
    }
    return new RegExp('^.{' + daysToBeg + (wholePeriod ? '' : ',' + (daysToBeg + daysPeriod - minFreeDays)) + '}(' + (allowTempRes ? '[tf]' : 'f') + '{' + daysPeriod + '})');
  }, [filterContext]);

  useEffect(() => {
    mapObject.facesLayer.getSource().on('featuresloadend', handleFacesLoad);
    mapObject.map.on('singleclick', handleMapClick);
    mapObject.geolocation.on('error', handleGeolocationError);
    mapObject.map.setTarget(mapRef.current);
    return () => {
      mapObject.facesLayer.getSource().un('featuresloadend', handleFacesLoad);
      mapObject.map.un('singleclick', handleMapClick);
      mapObject.geolocation.un('error', handleGeolocationError);
      mapObject.map.setTarget(null);
    }
  }, []);

  useEffect(() => {
    if (!mobile) {
      mapObject.map.addControl(zoomControl)
    } else {
      console.log(zoomControl)
      mapObject.map.removeControl(zoomControl)
    }
  }, [mobile]);

  const updateFace = useCallback((rec) => {
    const occ = rec.occByDays;
    const feature = (mapObject.facesLayer && mapObject.facesLayer.getSource().getFeatureById(rec.id));
    const res = {...rec};
    if (!occ) {
      res.status = 'unknown';
    } else {
      if (freeRe.test(occ)) {
        res.status = 'free';
      } else {
        res.status = 'sold';
      }
    }
    const cartItem = faceContext.cart.find((item)=>{
      return (
        item.id === res.id &&
        +item.startDate.toDate() <= +filterContext.period.start.toDate() &&
        +item.endDate.toDate() >= +filterContext.period.end.toDate()
      )
    });
    res.inCart = !!cartItem ? 1 : 0;
    res.total = calcTotalByPeriod({
      price: rec.pricePerMonth,
      startDate: filterContext.period.start,
      endDate: filterContext.period.end,
      printCost: rec.printCost,
      deliveryCost: rec.deliveryCost
    });
    if (feature) {
      feature.setProperties({
        status: res.status,
        inCart: res.inCart,
        total: res.total
      });
    }
    return res;
  }, [freeRe, faceContext.cart, filterContext.period]);

  const handleFacesLoad = useCallback((evt) => {
    const loaded = evt.features.reduce((res, f) => {
      // console.log(f.getId() + ' : ' + f.get('id'));
      if (!faceContext.faces[f.getId()]) {
        res[f.getId()] = f.getProperties();
        res[f.getId()].pricePerMonth = Number(f.get('price')) + Number(f.get('printCost')) + Number(f.get('deliveryCost'));
        f.set('pricePerMonth', res[f.getId()].pricePerMonth);
        res[f.getId()]['feature'] = f;
        res[f.getId()] = updateFace(res[f.getId()]);
      }
      return res;
    }, {});
    if (Object.keys(loaded).length) {
      // console.log('loaded ' + Object.keys(loaded).length + ' faces');
      const newFaces = {...faceContext.faces, ...loaded};
      // console.log(newFaces);
      setFaces(newFaces);

      // try to fix labels - no luck
      // const reversed = [...evt.features];
      // // console.log(evt.features)
      // reversed.reverse();
      // // console.log(reversed);
      // reversed.forEach((f) => {
      //   fll.getSource().addFeature(f);
      // })
    }
  }, [faceContext.faces, setFaces, updateFace]);

  // useTraceUpdate({ setMapObject, searchPlace });

  useEffect(() => {
    if (!faceContext.place || !faceContext.place[0] || !mapObject) {
      return;
    }
    const coords = olProj.fromLonLat([faceContext.place[0].lon, faceContext.place[0].lat]);
    if (coords) {
      const size = mapObject.map.getSize();
      const extent = mapObject.map.getView().calculateExtent();
      extent[1] = extent[1] + (extent[3] - extent[1]) / 2;
      if (!olExtent.containsCoordinate(extent, coords)) {
        const pixel = mapObject.map.getPixelFromCoordinate(coords);
        pixel[1] = pixel[1] + size[1] / 4;
        const newCenter = mapObject.map.getCoordinateFromPixel(pixel);
        mapObject.map.getView().animate({center: newCenter});
      }
    }
  }, [faceContext.place]);
  //
  useEffect(() => {
    mapObject.searchPlaceLayer.getSource().clear();
    if (searchPlace) {
      const place = new Feature({
        geometry: new Point(olProj.fromLonLat(searchPlace))
      });
      mapObject.searchPlaceLayer.getSource().addFeature(place);
      // me.map.getView().animate({center: place.getGeometry().getCoordinates()});
      mapObject.map.getView().animate({center: place.getGeometry().getCoordinates()});
    }
  }, [searchPlace]);

  useEffect(() => {
    if (!mapObject || !mapObject.curFaceLayer) {
      return;
    }
    mapObject.curFaceLayer.getSource().clear();
    if (!faceContext.face) {
      return;
    }
    if (faceContext.face) {
      mapObject.curFaceLayer.getSource().addFeature(faceContext.face.feature);
    }
  }, [faceContext.face]);

  useEffect(() => {
    Object.keys(faceContext.faces).forEach((id) => {
      faceContext.faces[id] = updateFace(faceContext.faces[id]);
      if (faceContext.face && faceContext.face.id === id) {
        faceContext.setFace(faceContext.faces[id]);
      }
    })
    if (!mapObject || !mapObject.facesLayer || !mapObject.facesLabelLayer) {
      return;
    }
    mapObject.facesLabelLayer.setStyle(createLabelStyle);
  }, [faceContext, faceContext.faces, filterContext.period, faceContext.cart, faceContext.setFace, updateFace, createLabelStyle]);

  useEffect(() => {
    if (!mapObject || !mapObject.facesLayer) {
      return;
    }
    mapObject.facesLayer.updateStyleVariables({maxPrice: +filterContext.price || 1000000});
    mapObject.soldLayer.updateStyleVariables({maxPrice: +filterContext.price || 1000000});
    mapObject.facesLabelLayer.setStyle(createLabelStyle);
  }, [filterContext.price, createLabelStyle]);

  return (<>
      <div ref={mapRef} className="ol-map">
      </div>
      <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{width: '100%'}}>
          Unable to retrieve your location
        </Alert>
      </Snackbar>
    </>
  )
}

export default Map;
