import {createTheme, responsiveFontSizes} from '@mui/material/styles';
import { lightBlue, red } from '@mui/material/colors';

let theme = createTheme({
  palette: {
    primary: {
      light: lightBlue[300],
      main: lightBlue[700],
      dark: lightBlue[900],
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#FFFFFF',
      contrastText: '#000000'
    }
  },
  typography: {
    fontFamily: [
      '"Rubik"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    button: {
      textTransform: 'none',
      fontWeight: "bold"
    }
  },
  components: {
    MuiFormLabel: {
      asterisk: {
        symbol: '**'
      }
    },
    MuiButton: {
      variants: [
        {
          props: {variant: 'addbutton'},
          style: {
            backgroundColor: lightBlue[700],
            color: "white",
            '&:hover': {
              backgroundColor: lightBlue[900]
            },
          },
        },
        {
          props: {variant: 'sendbutton'},
          style: {
            backgroundColor: "white",
            color: red["500"],
            '&:hover': {
              backgroundColor: red["A700"]
            },
          },
        },
      ]
    }
  }
});
export default responsiveFontSizes(theme);
